@font-face {
  font-family: "Brandon-Grot-W01-Light";
  src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e00ba30a-0bf3-4c76-9392-8641fa237a92.eot?#iefix");
  src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e00ba30a-0bf3-4c76-9392-8641fa237a92.eot?#iefix")
      format("eot"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/69b40392-453a-438a-a121-a49e5fbc9213.woff2")
      format("woff2"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/9362bca5-b362-4543-a051-2129e2def911.woff")
      format("woff"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/85060878-ca6c-43cc-ac31-7edccfdca71b.ttf")
      format("truetype"),
    url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/47f089a6-c8ce-46fa-b98f-03b8c0619d8a.svg#47f089a6-c8ce-46fa-b98f-03b8c0619d8a")
      format("svg");
}

html {
  scroll-behavior: smooth;
}

.scroll-icon {
  margin-bottom: 1rem;
}

header {
  nav {
    position: relative;
    #logo {
      width: 100px;
    }
  }

  .hero {
    .hero-image {
      background: url("/images/cloud_engineer_optimized.jpg") no-repeat scroll
        right 0px;
      background-size: cover;
      @media screen and (min-width: 768px) and (min-height: 500px) {
        background-attachment: fixed;
      }
    }
  }
  .menu-wrapper {
    position: absolute;
    top: 30px;
    right: 15px;
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid rgba(0, 0, 0, 1);
    border-radius: 5px;
    text-align: right;
    @media screen and (min-width: 768px) {
      // Horizontal Tablet and larger screens
      top: 15px;
      border: 0;
      background: none;
      position: relative;
      display: flex;
      align-items: center;
    }
    .menu-icon-wrapper {
      position: relative;
      img {
        // mobile menu cloud image
        position: relative;
        float: right;
        margin: 4px;
        width: 50px;
        height: 36px;
        @media screen and (min-width: 768px) {
          display: none;
        }
      }
      .menu-text {
        color: rgba(255, 255, 255, 0.9);
        position: absolute;
        top: 21px;
        right: 12px;
        font-size: 0.7rem;
        font-weight: 700;
        @media screen and (min-width: 768px) {
          display: none;
        }
      }
    }

    .trigger {
      // container of the navigation menu links
      clear: both;
      display: none;
      @media screen and (min-width: 768px) {
        display: flex;
      }
      .link {
        // border: 1px solid red;
        color: #fff;
        display: block;
        // margin: 20px 10px;
        padding: 5px 10px;
        margin-left: 50px;
        @media screen and (min-width: 768px) {
          color: #000;
          margin: 0;
        }
      }
    }
    &:hover .menu-icon-wrapper img {
      // border: 2px solid yellow;
      margin-bottom: 25px;
    }
    &:hover .trigger {
      display: block;
      @media screen and (min-width: 768px) {
        display: flex;
      }
    }
  }

  .subtitle {
    content: "";
    background: rgba(0, 0, 0, 0.6);
    .font_4 {
      font: normal normal normal 42px/1.25em brandon-grot-w01-light, sans-serif;
      color: #fff;
      margin-bottom: 0;
      @media screen and (min-height: 500px) {
        margin-bottom: 13rem;
      }
    }
  }
  .hero-image,
  .subtitle {
    min-height: calc(100vh - 150px);
    @media screen and (min-width: 768px) and (min-height: 500px) {
      min-height: calc(100vh - 100px);
    }
  }

  ul {
    li {
      a {
        &:hover {
          color: #000;
        }
        color: #000;
        padding: 0 10px;
      }
    }
  }
}

main {
}

footer {
}

a:hover {
  text-decoration: none;
}
